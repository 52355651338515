const meta = {
    siteTitle: 'Pasta & Pomodoro - Ausgezeichnete Italienische qualität auf räden.',
    siteDescription:
        'STEHEN VERSCHIEDENE NUDELGERICHTE AUSSERDEM GIBT ES HANDWERKLICH HERGESTELLTEN KÄSE, SCHINKEN, SALAMI',
    siteHeadline: 'Pasta & Pomodoro - Ausgezeichnete Italienische qualität auf räden.',
    siteTitleAlt: 'Pasta und pomodoro',
    siteShortName: 'Pasta & Pomodoro',
    siteUrl: 'https://www.pastaundpomodoro.com',
}

const social = {
    siteLogo: `${meta.siteUrl}/social/logo-pasta.png`,
    siteBanner: `${meta.siteUrl}/social/logo-pasta.png`,
    siteBannerWidth: '250',
    siteBannerHeight: '125',
}

const website = {
    ...meta,
    ...social,
    themeColor: '#3d3d3d',
    backgroundColor: '#dfddd8',
}

module.exports = website
