import * as React from 'react'
import Container from '../container'
import Translate from '../translation/translate'
import './styles.scss'

import InstagramIcon from '../../../static/insta-icon.svg'
import FacebookIcon from '../../../static/face-icon.svg'

const Footer = () => {
    return (
        <div className="footer">
            <div>
                <Container className="container-footer" size="big">
                    <div className="section-footer">
                        <h4>
                            <Translate>FOOTER.CONTACTS</Translate>
                        </h4>
                        <a
                            href={`tel:${Translate({ children: 'CONTACTS.PHONE' })}`}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <Translate>CONTACTS.PHONE</Translate>
                        </a>
                        <a
                            href={`mailto:${Translate({ children: 'CONTACTS.EMAIL' })}`}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <Translate>CONTACTS.EMAIL</Translate>
                        </a>
                    </div>

                    <div className="section-footer socials">
                        <h4>
                            <Translate>FOOTER.SOCIALS</Translate>
                        </h4>
                        <div>
                            <a
                                href={Translate({ children: 'SOCIALS.INSTAGRAM' })}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <img src={InstagramIcon} alt="" />
                            </a>
                            <a
                                href={Translate({ children: 'SOCIALS.FACEBOOK' })}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <img src={FacebookIcon} alt="" />
                            </a>
                        </div>
                    </div>

                    <div className="section-footer">
                        <h4>
                            <Translate>FOOTER.ORDER</Translate>
                        </h4>
                        <a
                            href={`mailto:${Translate({ children: 'SOCIALS.LIFERANDO' })}`}
                            rel="noopener noreferrer"
                        >
                            <Translate>FOOTER.LIFERANDO</Translate>
                        </a>
                    </div>
                </Container>
            </div>
        </div>
    )
}

export default Footer
