/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React from 'react'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { useLocation } from '@reach/router'
import Translate from './translation/translate'

const config = require('../../config/website')

const replaceTrailing = (_path) => _path.replace(/\/$/, ``)

const Head = (props) => {
    const {
        data: {
            site: { siteMetadata },
        },
    } = props
    const location = useLocation()
    const { pathname } = location
    const baseUrl = siteMetadata.siteUrl
    const fullUrl = `${baseUrl}${replaceTrailing(pathname)}`

    const title = Translate({ children: 'SITE_CONFIG.siteTitle' })
    const description = Translate({ children: 'SITE_CONFIG.siteDescription' })
    const image = config.siteBanner
    const imageWidth = config.siteBannerWidth
    const imageHeight = config.siteBannerHeight

    // TODO ADD BREADCRUMB FOR ALL PAGES
    const breadcrumb = {
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        'description': 'Breadcrumbs list',
        'name': 'Breadcrumbs',
        'itemListElement': [],
    }

    // schema for SiteNavigationElement
    const siteNavigationSchema = {
        '@context': 'http://schema.org',
        '@type': 'SiteNavigationElement',
        'name': ['Home'],
        'url': [`${baseUrl}`],
    }

    // schema for Organization Identity and Creator
    const generateOrgSchema = ({ input }) => ({
        '@context': 'http://schema.org',
        '@id': `${baseUrl}/#${input}`,
        '@type': 'Organization',
        'address': {
            '@type': 'PostalAddress',
            'addressCountry': 'DE',
            'addressLocality': '',
            'postalCode': '',
        },
        'name': Translate({ children: 'SITE_CONFIG.siteTitle' }),
        'alternateName': config.siteTitleAlt,
        'description': Translate({ children: 'SITE_CONFIG.siteDescription' }),
        'url': baseUrl,
        'email': 'pastaundpomodoro@gmail.com',
        'image': {
            '@type': 'ImageObject',
            'url': config.siteLogo,
            'height': '200',
            'width': '200',
        },
        'logo': {
            '@type': 'ImageObject',
            'url': config.siteLogo,
            'height': '60',
            'width': '60',
        },
    })

    return (
        <>
            <Helmet>
                <html lang={Translate({ children: 'SITE_CONFIG.htmlLang' })} />
                <title>{title}</title>
                <link rel="alternate" hrefLang="x-default" href={fullUrl} />
                <meta
                    httpEquiv="content-language"
                    content={Translate({ children: 'SITE_CONFIG.locale' })}
                />
                <meta name="description" content={description} />
                <meta name="image" content={image} />

                <meta name="msapplication-TileColor" content="#333533" />
                <meta name="msapplication-TileImage" content="/favicons/ms-icon-144x144.png" />
                <link rel="apple-touch-icon" sizes="57x57" href="/favicons/apple-icon-57x57.png" />
                <link rel="apple-touch-icon" sizes="60x60" href="/favicons/apple-icon-60x60.png" />
                <link rel="apple-touch-icon" sizes="72x72" href="/favicons/apple-icon-72x72.png" />
                <link rel="apple-touch-icon" sizes="76x76" href="/favicons/apple-icon-76x76.png" />
                <link
                    rel="apple-touch-icon"
                    sizes="114x114"
                    href="/favicons/apple-icon-114x114.png"
                />
                <link
                    rel="apple-touch-icon"
                    sizes="120x120"
                    href="/favicons/apple-icon-120x120.png"
                />
                <link
                    rel="apple-touch-icon"
                    sizes="144x144"
                    href="/favicons/apple-icon-144x144.png"
                />
                <link
                    rel="apple-touch-icon"
                    sizes="152x152"
                    href="/favicons/apple-icon-152x152.png"
                />
                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href="/favicons/apple-icon-180x180.png"
                />
                <link rel="apple-touch-icon" sizes="192x192" href="/favicons/apple-icon.png" />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="192x192"
                    href="/favicons/android-icon-192x192.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href="/favicons/favicon-32x32.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="96x96"
                    href="/favicons/favicon-96x96.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href="/favicons/favicon-16x16.png"
                />

                <meta
                    property="og:locale"
                    content={Translate({ children: 'SITE_CONFIG.ogLang' })}
                />
                <meta property="og:site_name" content={config.siteTitleAlt} />
                <meta property="og:url" content={fullUrl} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content={image} />
                <meta property="og:image:alt" content={description} />
                <meta property="og:image:width" content={imageWidth} />
                <meta property="og:image:height" content={imageHeight} />
                <meta
                    property="og:see_also"
                    content="https://www.instagram.com/pastaundpomodoro/?utm_medium=copy_link"
                />

                <meta name="twitter:card" content="summary_large_image" />
                {/*  <meta name="twitter:creator" content={config.twitter} /> */}
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:image" content={image} />
                <meta name="twitter:image:alt" content={description} />
                <meta name="twitter:image:width" content={imageWidth} />
                <meta name="twitter:image:height" content={imageHeight} />

                <link type="text/plain" href={`${baseUrl}/humans.txt`} rel="author" />

                {/** SiteNavigationElement Schema */}
                <script type="application/ld+json">{JSON.stringify(siteNavigationSchema)}</script>

                {/** Identity Schema */}
                <script type="application/ld+json">
                    {JSON.stringify(
                        generateOrgSchema({
                            input: 'identity',
                        }),
                    )}
                </script>

                {/** Breadcrumbs Schema */}
                {breadcrumb.itemListElement.length && (
                    <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
                )}
            </Helmet>
        </>
    )
}

const SEO = (props) => (
    <StaticQuery query={querySEO} render={(queryData) => <Head {...props} data={queryData} />} />
)

export default SEO

const querySEO = graphql`
    query PageQuery {
        site {
            buildTime(formatString: "YYYY-MM-DD")
            siteMetadata {
                siteUrl
            }
        }
    }
`
