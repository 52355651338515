/* eslint-disable react/prop-types */
import * as React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
    position: relative;
    margin: 0 auto;
    padding: 0 1.5rem;
    max-width: ${(props) => (props.size === 'big' ? 1600 : 1300)}px;
`

const Container = ({ children, className, size = 'default' }) => (
    <Wrapper className={className} size={size}>
        {children}
    </Wrapper>
)

export default Container
