/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable consistent-return */
import React from 'react'
import { Link } from 'gatsby'
import Container from '../container'
/* import Translate from '../translation/translate'
import NavItem from './NavItem' */
import Logo from '../../../static/logo-pasta.svg'
import './styles.scss'

export default () => {
    const [scrolled, setScrolled] = React.useState(false)
    const [offset, setOffset] = React.useState(0)
    const [windowheight, setWindowHeight] = React.useState(0)

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: `smooth` })
    }

    React.useEffect(() => {
        if (offset > windowheight * 0.6) {
            setScrolled(true)
        } else {
            setScrolled(false)
        }
    }, [offset, windowheight])

    React.useEffect(() => {
        if (typeof window === 'undefined') return
        window.onscroll = () => {
            setOffset(window.pageYOffset)
        }
    }, [])
    React.useLayoutEffect(() => {
        if (typeof window === 'undefined') return
        setWindowHeight(window.innerHeight)
        const handleResize = () => setWindowHeight(window.innerHeight)
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])
    return (
        <div className={`navigation-wrapper ${offset > windowheight * 0.6 ? 'visible' : ''}`}>
            <Container className="navigation-container" size="big">
                <div className="logo-div" onClick={() => scrollToTop()}>
                    <Link to="/">
                        <img src={Logo} alt="" />
                    </Link>
                </div>
                {/* <div className="navigation-items">
                    <Link to="/">
                        <NavItem>
                            <Translate>NAVIGATION.ABOUT</Translate>
                        </NavItem>
                    </Link>
                    <Link to="/menu">
                        <NavItem>
                            <Translate>NAVIGATION.MENU</Translate>
                        </NavItem>
                    </Link>
                    <Link to="/location">
                        <NavItem>
                            <Translate>NAVIGATION.LOCATION</Translate>
                        </NavItem>
                    </Link>
                </div> */}
                <div className="language-selectors">
                    <Link to="/">
                        <h3>DEU</h3>
                    </Link>
                    <Link to="/en">
                        <h3>EN</h3>
                    </Link>
                </div>
            </Container>
            <div className={`shadow ${scrolled ? 'show' : ''}`} />
        </div>
    )
}
