import React from 'react'
import SEO from '../SEO'
import Footer from '../footer'
import Navigation from '../navigation'

import './styles.scss'

const Layout = (props) => {
    const { location, children, className } = props

    return (
        <>
            <div className={className}>
                <SEO />
                <Navigation />
                <div className="page-wrapper">{children}</div>
                <Footer />
            </div>
        </>
    )
}

export default Layout
