/* eslint-disable prefer-destructuring */
import i18n from 'i18n-js'
import { useStaticQuery, graphql } from 'gatsby'
import ReactHtmlParser from 'react-html-parser'

import localEnTranslations from './en.json'
import localDeTranslations from './de.json'

const Translate = ({ config, children, textOnly = false }) => {
    let path = ''
    if (typeof window !== 'undefined') {
        path = window.location.pathname.split('/')[1]
    }
    const language = path !== '' ? `${path}-${path.toUpperCase()}` : 'de-DE'
    const contentfulLocales = useStaticQuery(graphql`
        query localesQuery {
            allContentfulLocale {
                edges {
                    node {
                        locale
                        data {
                            internal {
                                content
                            }
                        }
                    }
                }
            }
        }
    `)

    const { edges } = contentfulLocales.allContentfulLocale

    const data = edges.find((item) => item.node.locale.includes(language))
    const filteredLocalTranslation = language.includes('de')
        ? localDeTranslations
        : localEnTranslations

    if (data === null) return `${children} - NO LOCALE`

    try {
        const translation =
            process.env.NODE_ENV !== 'development'
                ? data.node.data.internal.content
                : filteredLocalTranslation

        i18n.defaultLocale = 'de-DE'
        i18n.locale = 'de-DE'
        i18n.translations['de-DE'] =
            typeof translation === 'string' ? JSON.parse(translation) : translation

        const text = i18n.t(children, config)

        return textOnly ? text : ReactHtmlParser(text)
    } catch (err) {
        return `${children} - NO TRANSLATION`
    }
}

export default Translate
